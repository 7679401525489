<template>
<v-row
            class="fill-height">
        <v-container
                class="fill-height justify-center my-auto"
                fluid>
            <v-card
                    width="400">
                <v-card-title>
                   Payment Successful
                </v-card-title>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn @click="$router.push('/')">Back to Home Page</v-btn>
                </v-card-actions>
            </v-card>
        </v-container>
    </v-row>
</template>

<script>
export default {
  name: 'success'
};
</script>